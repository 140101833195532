export default {
  primary: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
    fontFamily: 'heading',
    color: 'light',
    borderBottom: 'solid 2px',
    borderColor: 'primary',
    borderRadius: '0px',
    fontSize: '0.8rem',
    textTransform: 'capitalize',
    backgroundColor: 'rgb(0 0 0 / 28%)',
    ':hover': {
      backgroundColor: 'primary',
      opacity: '0.8',
      transition: 'all ease-in-out 0.4s',
      borderColor: 'light'
    }
  },
  secondary: {
    backgroundColor: 'transparent',
    color: 'primary',
    border: 'solid 2px',
    borderColor: 'primary',
    padding: '0.75rem 1.5rem',
    fontSize: '0.8rem',
    ':hover': {
      backgroundColor: 'primary',
      color: 'white'
    }
  }
}
